import * as $ from 'jquery';
import 'popper.js';
//import 'json-loader/index.js';
//import "particles.js";
//import "./particles.json";

import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/collapse.js';
import './custom.js';

//import './particles.js';
//import './app.js';

// Import SCSS
import '../styles/index.scss';

// Add js-cookie: https://github.com/js-cookie/js-cookie
import Cookie from 'js-cookie';


// Add Lightbox2: https://lokeshdhakar.com/projects/lightbox2/
import 'lightbox2/dist/css/lightbox.css';
window.lightbox = require('lightbox2');
